import React from 'react';
import Carousel from '../components/CarouselComp';


class TPRHome extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        return <div className='tpr_home_body'>
            <Carousel />
        </div>
    }
}

export default TPRHome;
