import Dexie from 'dexie';
import { checkNullAndReturnString } from './Common';

const db = new Dexie("TeqbahnLabs")
db.version(1).stores({ store: "key" })


export function writeBinaryToDB(fileName, contents) {
    if (checkNullAndReturnString(fileName)) {
        db.store.put({
            key: fileName,
            value: contents
        })
    }
}

export async function readBinaryFromDB(fileName) {
    if (checkNullAndReturnString(fileName)) {
        return await db.store.where('key').equals(fileName).first().then((data) => {
            return data ? data.value : ""; // Important: Understand what 'return' means here!
        })
    } 

    return ""
}