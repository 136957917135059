import React from 'react';
import {
    Link,
    withRouter
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import logo from '../assets/images/logo.png'
import MyConstant from '../config/MyConstant';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagename: "",
            email: "",
            password: "",
            passwordVisible: false,
            RoleActive: {},
        }
    }
    componentDidMount() {
    }


    login() {
        let { email, password, RoleActive } = this.state;
        if (email === "" || password === "") {
            toast.error('Please enter username/password.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        } else {
            if(email === "dicomadmin" && password === "zTr9n91*") {
                this.props.history.push(MyConstant.keyList.projectUrl + "/user")
            } else {
                toast.error("Please enter valid username/password.", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }
        }
    }
    render() {
        let { passwordVisible } = this.state;
        return (<div className='login-page'>
            <ToastContainer />
            <div className="box">
                <div className="logo">
                    {/* <img src={logo} alt="TeqLabs" className='img-fluid'/> */}
                    Dicom and PDF viewer
                </div>
                <div className="welcome-text my-5">
                    Welcome!
                </div>
                <div className="">
                    <div className="row mx-0 login-form px-2 py-2">
                        <div className='offset-sm-2 col-sm-8'>
                            <div className="form-group">
                                <label>Username</label>
                                <input type="text" className="form-control" placeholder="Enter Username" onChange={(e) => {
                                    this.setState({
                                        email: e.target.value
                                    })
                                }}
                                    autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                            </div>
                            <div className="form-group pb-2">
                                <label>Password</label>
                                <div className="input-group mb-3">
                                    <input type={passwordVisible ? "text" : "password"} className="form-control" placeholder="Enter Password" onChange={(e) => {
                                        this.setState({
                                            password: e.target.value
                                        })
                                    }}
                                        autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                                    <div className="input-group-append">
                                        <span className="input-group-text c-pointer" onClick={() => {
                                            this.setState({
                                                passwordVisible: !passwordVisible
                                            })
                                        }}>
                                            <i className={`fas ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`}></i>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="d-grid gap-2">
                                <button type="button" className="btn btn-block user-login-btn" onClick={() => this.login()}>Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

}

export default withRouter(Login);