import React, { useEffect, useState } from 'react';
import {
    withRouter,
    Link
} from "react-router-dom";
import DatatableFromData from '../../components/DatatableFromData';
import Modal from '../../components/Modal';
import Dropzone from 'react-dropzone';
import Dragplaceholder from '../../assets/Dragfile.png';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import dicomFileViewerPb from "../../protobuf/js/dicomFileViewer_pb";
import { getUuidv4 } from '../../config/Common';
import MyConstant from '../../config/MyConstant';
import { readBinary, uploadDocNewUI, writeBinary } from '../../config/MyFileSystem';

const dicomFileViewer = new dicomFileViewerPb.DicomFileViewer();
let dicomFileViewerPbList = new dicomFileViewerPb.DicomFileViewerList();

function DicomViewer(props) {
    const [filemodal, setFileModal] = useState(false)
    const [loader, setLoader] = useState(false)
    const [name, setName] = useState("")
    const [files, setFiles] = useState([])
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    let tableTxtData = [
        {
            column: "Name",
            selector: 'name',
        },
        {
            column: "View",
            cell: (row, key) => {
                return <button className="btn btn-success btn-sm" onClick={() => {
                    view(row, key)
                }}>View</button>
            }
        },
    ]

    useEffect(() => {
        updateFileListData()
    }, [])


    function view(row, key) {
        props.history.push(MyConstant.keyList.projectUrl + "/user/dicom-file-viewer/" + row.id, {
            files: row.files
        })
    }

    async function updateFileListData() {
        var existsData = await read(true)
        setData(existsData.dicomfileviewerList)
    }

    async function save() {
        if (name.trim() === "") {
            alert("Please enter name");
            return false;
        }
        if (files.length === 0) {
            alert("Please upload files");
            return false;
        }

        setLoader(true)
        let id = getUuidv4()

        var listPath = []
        for (let i = 0; i < files.length; i++) {
            var destPath = await uploadDocNewUI(id, files[i], "dicom")
            listPath.push(destPath)
        }


        let listpathString = JSON.stringify(listPath)
        dicomFileViewer.setId(id);
        dicomFileViewer.setName(name);
        dicomFileViewer.setFiles(listpathString);

        var existData = await read(false)
        if (existData) {
            dicomFileViewerPbList = existData
        }
        else {
            dicomFileViewerPbList["wrappers_"] = null
            dicomFileViewerPbList["array"][0] = []
        }
        dicomFileViewerPbList.addDicomfileviewer(dicomFileViewer);
        const serializedData = dicomFileViewerPbList.serializeBinary();
        writeBinary(MyConstant.Keys.dicomviewer, serializedData);


        setLoader(false);
        setFileModal(false)
        setName("")
        setFiles([])
        updateFileListData()
        toast.success("File uploaded successfully.", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
    }

    async function read(withoutObj) {
        let data = await readBinary({ key: MyConstant.Keys.dicomviewer, withoutObj })
        return data
    }


    function onFileChangeCapture(uploadFiles) {
        let tempFiles = []
        Array.from(uploadFiles).map((file) => {
            file.tempUrl = URL.createObjectURL(file)
            tempFiles.push(file)
        })
        setFiles(tempFiles)
    };

    function closeIframeLoader() {
        setLoading(false)
    }

    return <div style={{height: "100%"}}>
        {
            loading && <div className='loader-apps'>
                <div className="loading text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        }
        <iframe allow="camera; microphone" onLoad={() => closeIframeLoader()} border={0} frameBorder={0} src={"https://dhek.in/viewer/1.3.6.1.4.1.14519.5.2.1.7009.2403.871108593056125491804754960339"} style={{ height: '100%', width: '100%', background: '#fff', overflow: "auto" }} />
        
    </div>;
}

export default withRouter(DicomViewer)