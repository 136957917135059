import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { createBrowserHistory } from 'history'
import './App.css';
import MyConstant from "./config/MyConstant";
import TPRHome from "./pages/TPRHome";
import Login from "./pages/Login";
import HomeRoute from "./pages/HomeRoute";

const history = createBrowserHistory();

function App() {
  return <Router>
    <Switch>
      <Route exact path={MyConstant.keyList.projectUrl + "/"}>
        <Login history={history} />
      </Route>
      <Route path={MyConstant.keyList.projectUrl + "/user"}>
        <HomeRoute history={history} />
      </Route>
    </Switch>
  </Router>;
}

export default App;
