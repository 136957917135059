import React, { useEffect, useState } from 'react';
import {
    withRouter,
    Link
} from "react-router-dom";
import DatatableFromData from '../../components/DatatableFromData';
import Modal from '../../components/Modal';
import Dropzone from 'react-dropzone';
import Dragplaceholder from '../../assets/Dragfile.png';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import pdfFileViewerPb from "../../protobuf/js/pdfFileViewer_pb";
import { getUuidv4 } from '../../config/Common';
import MyConstant from '../../config/MyConstant';
import { readBinary, uploadDocNewUI, writeBinary } from '../../config/MyFileSystem';

const pdfFileViewer = new pdfFileViewerPb.PdfFileViewer();
let pdfFileViewerPbList = new pdfFileViewerPb.PdfFileViewerList();

function PDFviewer(props) {
    const [filemodal, setFileModal] = useState(false)
    const [loader, setLoader] = useState(false)
    const [name, setName] = useState("")
    const [files, setFiles] = useState([])
    const [data, setData] = useState([])

    let tableTxtData = [
        {
            column: "Name",
            selector: 'name',
        },
        {
            column: "View",
            cell: (row, key) => {
                return <button className="btn btn-success btn-sm" onClick={() => {
                    view(row, key)
                }}>View</button>
            }
        },
    ]

    useEffect(() => {
        updateFileListData()
    }, [])


    function view(row, key) {
        props.history.push(MyConstant.keyList.projectUrl + "/user/pdf-file-viewer/"+row.id, {
            files: row.files
        })
    }

    async function updateFileListData() {
        var existsData = await read(true)
        setData(existsData.pdffileviewerList)
    }

    async function save() {
        if(name.trim() === "") {
            alert("Please enter name");
            return false;
        }
        if(files.length === 0) {
            alert("Please upload files");
            return false;
        }

        setLoader(true)
        let id = getUuidv4()

        var listPath = []
        for (let i = 0; i < files.length; i++) {
            var destPath = await uploadDocNewUI(id, files[i], "pdf")
            listPath.push(destPath)
        }


        let listpathString = JSON.stringify(listPath)
        pdfFileViewer.setId(id);
        pdfFileViewer.setName(name);
        pdfFileViewer.setFiles(listpathString);
        
        var existData = await read(false)
        if (existData) {
            pdfFileViewerPbList = existData
        }
        else {
            pdfFileViewerPbList["wrappers_"] = null
            pdfFileViewerPbList["array"][0] = []
        }
        pdfFileViewerPbList.addPdffileviewer(pdfFileViewer);
        const serializedData = pdfFileViewerPbList.serializeBinary();
        writeBinary(MyConstant.Keys.pdfviewer, serializedData);


        setLoader(false);
        setFileModal(false)
        setName("")
        setFiles([])
        updateFileListData()
        toast.success("File uploaded successfully.", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
    }

    async function read(withoutObj) {
        let data = await readBinary({ key: MyConstant.Keys.pdfviewer, withoutObj })
        return data
    }
    

    function onFileChangeCapture(uploadFiles) {
        let tempFiles = []
        Array.from(uploadFiles).map((file) => {
            file.tempUrl = URL.createObjectURL(file)
            tempFiles.push(file)
        })
        setFiles(tempFiles)
    };

    return <div className='p-2'>
        <ToastContainer />
        <div className='text-right my-2'>
            <button className='btn btn-primary' onClick={() => setFileModal(true)}>Add File</button>
        </div>
        <DatatableFromData
            result={data}
            data={tableTxtData}
        />

        {
            filemodal && <Modal
                visible={filemodal}
                closeModal={() => setFileModal(false)}
                heading={`Add Files`}
                body={
                    <div>
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" className="form-control" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)}/>
                        </div>
                        <div className="form-group">
                            <label>File</label>
                            <div className="dropback">
                                <Dropzone accept=".pdf" multiple={true}
                                    onDrop={(acceptedFiles) => {
                                        onFileChangeCapture(acceptedFiles)
                                    }} onDropRejected={(reject) => {
                                        toast.error("Please Choose Image / Text File.", {
                                            position: "top-right",
                                            autoClose: 1000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true
                                        });

                                    }}  >
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                <input {...getInputProps()} />
                                                <div className="row">
                                                    <div className="col-3">
                                                        <img src={Dragplaceholder} className='dragimgph' />
                                                    </div>
                                                    <div className="col-9">
                                                        <p className='dragtext'>
                                                            Drag files here, or click to select files and upload
                                                            <br />
                                                            Support file formats (.pdf)
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                        </div>
                        <div className='row my-2'>
                        {
                            files.map((f, loopIndex) => {
                                let filePath = ""
                                let fileName = ""
                                let fileType = ""

                                if (f instanceof File) {
                                    filePath = URL.createObjectURL(f)
                                    fileName = f.name
                                    fileType = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
                                }
                                
                                let returnData = ""
                                if (fileType === "pdf") {
                                    returnData = <i className="fa fa-file-pdf" aria-hidden="true"
                                        style={{ paddingRight: 5, fontSize: 80, cursor: "pointer", color: '#E91E63' }}
                                    ></i>
                                } 
                                return (<div className='col-sm-6 col-md-4 file-box' key={loopIndex}>
                                    {returnData}
                                    <div>{fileName}</div>
                                </div>)
                            })
                        }
                    </div>
                    </div>
                }
                footer={<React.Fragment>
                    <button type="button" className="btn btn-primary" onClick={() => save()}>
                        {loader && <i className="fa fa-spinner fa-spin"></i>}
                        <span className={`${loader && ("pl-1")}`}>
                            Save
                        </span>
                    </button>
                </React.Fragment>}
            />
        }
    </div>;
}

export default withRouter(PDFviewer)