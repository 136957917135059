import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'
import Slide_1 from '../images/TPR_Slide/TPR.Slidedeck_1.jpg';
import Slide_2 from '../images/TPR_Slide/TPR.Slidedeck_2.jpg';
import Slide_3 from '../images/TPR_Slide/TPR.Slidedeck_3.jpg';
import Slide_4 from '../images/TPR_Slide/TPR.Slidedeck_4.jpg';
import Slide_5 from '../images/TPR_Slide/TPR.Slidedeck_5.jpg';
import Slide_6 from '../images/TPR_Slide/TPR.Slidedeck_6.jpg';
import Slide_7 from '../images/TPR_Slide/TPR.Slidedeck_7.jpg';
import Slide_8 from '../images/TPR_Slide/TPR.Slidedeck_8.jpg';
import Slide_9 from '../images/TPR_Slide/TPR.Slidedeck_9.jpg';



class CarouselComp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            holdImage: true,
            oneTimeSet: false,
        }
    }

    changeStatus(type) {
        this.setState({ holdImage: type })
    }
    render() {
        let { holdImage } = this.state

        console.log("holdImage", holdImage)
        return (
            <div >
                <Carousel autoPlay={holdImage} interval={5000} width={"100%"} infiniteLoop={true} stopOnHover={false} showArrows={true} showStatus={false}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                            <span onClick={onClickHandler}>
                                <a href="javascript:void(0)" onClick={() => {
                                    this.changeStatus(true)
                                }} className='btn btn-sm  prevbtn'>
                                    <i className='fa fa-chevron-left' style={{ fontSize: "26px", color: "#FFF" }} />
                                </a>
                            </span>
                        )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                            <span onClick={onClickHandler}>
                                <a href="javascript:void(0)" onClick={() => {
                                    this.changeStatus(true)
                                }} title={label} className='btn btn-sm nextbtn' >
                                    <i className='fa fa-chevron-right' style={{ fontSize: "26px", color: "#FFF" }} />
                                </a>
                            </span>
                        )
                    }
                    showThumbs={false}
                    animationHandler="fade"

                >

                    <div className='' onClick={() => {
                        this.changeStatus(false)
                    }} >
                        <img src={Slide_1} className='carosal_img' style={{ height: window.innerWidth > window.innerHeight ? window.innerHeight : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide_2} style={{ height: window.innerWidth > window.innerHeight ? window.innerHeight : "auto" }}
                        />
                    </div>

                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide_3} style={{ height: window.innerWidth > window.innerHeight ? window.innerHeight : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide_4} style={{ height: window.innerWidth > window.innerHeight ? window.innerHeight : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide_5} style={{ height: window.innerWidth > window.innerHeight ? window.innerHeight : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide_6} style={{ height: window.innerWidth > window.innerHeight ? window.innerHeight : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide_7} style={{ height: window.innerWidth > window.innerHeight ? window.innerHeight : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide_8} style={{ height: window.innerWidth > window.innerHeight ? window.innerHeight : "auto" }}
                        />
                    </div>
                    <div onClick={() => {
                        this.changeStatus(false)
                    }}>
                        <img src={Slide_9} style={{ height: window.innerWidth > window.innerHeight ? window.innerHeight : "auto" }}
                        />
                    </div>
                </Carousel>
            </div>
        )
    }
}
export default CarouselComp;