import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import logo from '../assets/images/logo.png'
import MyConstant from '../config/MyConstant';
import PDFviewer from "./user/PDFviewer";
import {
    Link
} from "react-router-dom";
import DicomViewer from "./user/DicomViewer";
import PDFFileviewer from "./user/PDFFileviewer";
import DicomFileviewer from "./user/DicomFileviewer";

function HomeRoute(props) {
    const location = useLocation();
    let { path } = useRouteMatch();

    return <div className="users-main">
        <div className='content'>
            <div className='header'>
                {/* <div className='logo'>
                    <img className='img-fluid' alt="logo" src={logo} />
                </div> */}
                <div className='header-content'>
                    Dicom and PDF viewer
                </div>
            </div>
            <div className='main-content'>
                <div className='sidemenu'>
                    <ul className='menu'>
                        <li className={location.pathname === path + "" ? "nav-item active" : "nav-item"}>
                            <Link className="" to={MyConstant.keyList.projectUrl + "/user"}>
                                <span className="link-icon"><i className="far fa-file-pdf"></i></span>
                                <span className="link-text">PDF Viewer</span>
                            </Link>
                        </li>
                        <li className={location.pathname === path + "/dicom-viewer" ? "nav-item active" : "nav-item"}>
                            <Link className="" to={MyConstant.keyList.projectUrl + "/user/dicom-viewer"}>
                                <span className="link-icon"><i className="fas fa-briefcase-medical"></i></span>
                                <span className="link-text">Dicom Viewer</span>
                            </Link>
                        </li>
                        <li className={"nav-item"}>
                            <Link className="" to={MyConstant.keyList.projectUrl + "/"}>
                                <span className="link-icon"><i className="fas fa-sign-out-alt"></i></span>
                                <span className="link-text">Logout</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className='user-content'>
                    <Switch>
                        <Route exact path={`${path}`}>
                            <PDFviewer history={props.history} grpcClient={props.grpcClient} />
                        </Route>
                        <Route path={`${path}/pdf-file-viewer/:id`}>
                            <PDFFileviewer history={props.history} grpcClient={props.grpcClient} />
                        </Route>
                        <Route exact path={`${path}/dicom-viewer`}>
                            <DicomViewer history={props.history} grpcClient={props.grpcClient} />
                        </Route>
                        <Route exact path={`${path}/dicom-file-viewer/:id`}>
                            <DicomFileviewer history={props.history} grpcClient={props.grpcClient} />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>

    </div>;
}

export default HomeRoute;