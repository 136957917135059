import { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { PDFViewer } from 'react-view-pdf';
import { readFile } from "../../config/MyFileSystem";
import MyConstant from "../../config/MyConstant";

function PDFFileviewer(props) {
    let [activeIndex, setActiveIndex] = useState(0)
    let [uploadFiles, setUploadFiles] = useState([])
    let [next, setNext] = useState(false)
    let [prev, setPrev] = useState(false)
    let location = useLocation();
    useEffect(() => {
        let { files } = location.state;
        files = JSON.parse(files)
        console.log("files", files)
        if(files.length > 1) {
            setNext(true)
        }
        prepareFile(files)
    }, [])

    async function prepareFile(files) {
        var arrayFind = Array.isArray(files);
        if (arrayFind) {
            let images = []
            for (let i = 0; i < files.length; i++) {
                let data = await readFile({ filePath: files[i] })
                images.push({
                    uri: data,
                })
            }
            setUploadFiles(images)
        }
    }

    function changeSlide(action) {
        if(action === "next") {
            activeIndex = activeIndex + 1
        } else if(action === "prev") {
            activeIndex = activeIndex - 1
        }

        let nextCheck = false,
            prevCheck = false;

        if(activeIndex < (uploadFiles.length - 1)) {
            nextCheck = true;
        }
        
        if(activeIndex > 0) {
            prevCheck = true;
        } 
        setNext(nextCheck)
        setPrev(prevCheck)
        setActiveIndex(activeIndex)
    }

    return <div className="viewer-page">
        <div className="viewer-button my-2 mx-2 d-flex">
            <div style={{flex: 1}}>
                <button type="button" className={`btn btn-primary`} onClick={() => {
                    props.history.push(MyConstant.keyList.projectUrl + "/user")
                }}>
                    <i className="fa fa-chevron-left" />
                </button>
            </div>
            <div className="btn-group">
                <button type="button" className={`btn btn-secondary ${prev ? "" : "disabled"}`} onClick={() => {
                    if(prev) {
                        changeSlide("prev")
                    }
                }}><i className="fa fa-chevron-left" /></button>
                <button type="button" className={`btn btn-secondary ${next ? "" : "disabled"}`} onClick={() => {
                    if(next) {
                        changeSlide("next")
                    }
                }}><i className="fa fa-chevron-right" /></button>
            </div>
        </div>
        <div className="viewer-page">
            {
                uploadFiles[activeIndex] && <PDFViewer url={uploadFiles[activeIndex].uri} />
            }
        </div>
    </div>;
}

export default withRouter(PDFFileviewer)