import pdfFileViewerPb from "../protobuf/js/pdfFileViewer_pb";
import dicomFileViewerPb from "../protobuf/js/dicomFileViewer_pb";
import { checkNullAndReturnString, getUuidv4 } from "./Common";
import MyConstant from "./MyConstant";
import { readBinaryFromDB, writeBinaryToDB } from './MyDb';


export function writeBinary(fileName, contents) {
  if (checkNullAndReturnString(fileName)) {
    writeBinaryToDB(fileName, contents)
  }
}

export function writeFile(fileName, contents) {
  if (checkNullAndReturnString(fileName)) {
    writeBinaryToDB(fileName, contents)
  }
}

export async function readFile(obj) {
  let { filePath } = obj;
  let str = ""
  if (checkNullAndReturnString(filePath)) {
    str = await readBinaryFromDB(filePath)
  }

  return str;
}


export async function uploadDoc(file, docType) {
  var fileName = getUuidv4();
  let contents = await getBase64(file);

  let str = "File-" + docType + "-" + fileName;
  writeFile(str, contents)
  return str;
}


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}


export async function uploadDocNewUI(docId, file, docType) {
  var fileName = getUuidv4();
  let contents = await getBase64(file);

  let str = "File-" + docId + "-" + docType + "-" + fileName;
  writeFile(str, contents)
  return str;
}

export async function readBinary(obj) {
  let { key, withoutObj } = obj;
  let originalPath = key
  let data = await readBinaryFromDB(originalPath)
  let desData;
  switch (key) {
    case MyConstant.Keys.pdfviewer:
      desData = pdfFileViewerPb.PdfFileViewerList.deserializeBinary(data);
      break;
    case MyConstant.Keys.dicomviewer:
      desData = dicomFileViewerPb.DicomFileViewerList.deserializeBinary(data);
      break;
  }

  if (withoutObj && (!Array.isArray(desData) && typeof desData === "object")) {
    desData = desData.toObject()
  }

  return desData
}

export function convertStrToUint(str) {
  if (str === undefined || str === '') {
    str = "{}"
  }

  let retrievedArr = JSON.parse(str);
  let retrievedTypedArray = new Uint8Array(retrievedArr);

  return retrievedTypedArray
}

